import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


const DepositThankYouPage = ({location}) => (
	<Layout location={location}>
		<SEO title="Thank You Page" description="continuing education" />
		<div class="page-content py-5">
			<div class="container">
				<div className="intro my-5 pt-5">
					<div className="row">
						<div className="col-md-9 mx-auto text-center">
							<h1 className="mt-5">Thank you for submitting your deposit</h1>
							<h4 className="text-muted">
								<strong>
									A member of our team will reach out shortly via
email to assist you through the next steps.
								</strong>
							</h4>
							<hr />
							<p className="d-flex flex-column flex-sm-row justify-content-center mt-5">
								<Link
									className="btn btn-primary"
									to="/"
									target="_blank"
									rel="noopener noreferrer"
								>
									BACK TO HOMEPAGE
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default DepositThankYouPage
